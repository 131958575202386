import Amazon from "../assets/Amazon.png"
import epicon from "../assets/epicon.png"
import hotstar from "../assets/hotstar.png"
import playbox from "../assets/playbox.png"
import sun from "../assets/sun.png"
import Shemaroo from "../assets/Shemaroo.png"
import sony from "../assets/sony.png"
import Zee5 from "../assets/ZEE5.png"
import Voot from "../assets/Voot.png"

export const tenMbpsPlan=[
   {days:"30 Days",price:"330/-"},
   {days:"90 Days",price:"900/-"},
   {days:"180 Days",price:"1,700/-"},
   {days:"360 Days",price:"3,300/-"},
]

export const twentyMbpsPlan=[
 
  {days:"30 Days",price:"400/-"},
   {days:"90 Days",price:"1,100/-"},
   {days:"180 Days",price:"2,100/-"},
   {days:"360 Days",price:"4,000/-"},
]
export const twentyFiveMbpsPlan=[
  
  {days:"30 Days",price:"450/-"},
   {days:"90 Days",price:"1,200/-"},
   {days:"180 Days",price:"2,300/-"},
   {days:"360 Days",price:"4,500/-"},
]
export const fiftyMbpsPlan=[
   
  {days:"30 Days",price:"550/-"},
   {days:"90 Days",price:"1,500/-"},
   {days:"180 Days",price:"2,850/-"},
   {days:"360 Days",price:"5,500/-"},
]
export const hunderedMbpsPlan=[
  
  {days:"30 Days",price:"620/-"},
   {days:"90 Days",price:"1,750/-"},
   {days:"180 Days",price:"3,250/-"},
   {days:"360 Days",price:"6,200/-"},
]
export const oneFiftyMbpsPlan=[

  {days:"30 Days",price:"750/-"},
   {days:"90 Days",price:"2,100/-"},
   {days:"180 Days",price:"4,000/-"},
   {days:"360 Days",price:"7,500/-"},
]
export const twoHundredMbpsPlan=[
  {days:"30 Days",price:"900/-"},
   {days:"90 Days",price:"2,600/-"},
   {days:"180 Days",price:"4,850/-"},
   {days:"360 Days",price:"9,000/-"},
]
export const threeHundredMbpsPlan=[
  {days:"30 Days",price:"1,100/-"},
   {days:"90 Days",price:"3,200/-"},
   {days:"180 Days",price:"6,000/-"},
   {days:"360 Days",price:"11,000/-"},
]

export const monthlyData=[
   {title:"Plan A",subTitle:"Starter Kit",plan:"15Mbps",day:"30 Days",price:"330/-"},
   {title:"Plan B",subTitle:"New Beginnings",plan:"25Mbps",day:"30 Days",price:"400/-"},
   {title:"Plan C",subTitle:"Love to Start",plan:"40Mbps",day:"30 Days",price:"450/-"},
   {title:"Plan C",subTitle:"Serious Start",plan:"70Mbps",day:"30 Days",price:"550/-"},
   {title:"Plan D",subTitle:"Good Start",plan:"100Mbps",day:"30 Days",price:"620/-"},
   {title:"Plan E",subTitle:"Smart Start",plan:"150Mbps",day:"30 Days",price:"750/-"},
   {title:"Plan F",subTitle:"Long Plans",plan:"200Mbps",day:"30 Days",price:"900/-"},
   {title:"Plan G",subTitle:"Huge Start",plan:"300Mbps",day:"30 Days",price:"1,100/-"}
]

export const threeMonthData=[
   {title:"Plan A",subTitle:"Starter Kit",plan:"15Mbps",day:"90 Days",price:"900/-"},
   {title:"Plan B",subTitle:"New Beginnings",plan:"25Mbps",day:"90 Days",price:"1,100/-"},
   {title:"Plan C",subTitle:"Love to Start",plan:"40Mbps",day:"90 Days",price:"1,200/-"},
   {title:"Plan C",subTitle:"Serious Start",plan:"70Mbps",day:"90 Days",price:"1,500/-"},
   {title:"Plan D",subTitle:"Good Start",plan:"100Mbps",day:"90 Days",price:"1,750/-"},
   {title:"Plan E",subTitle:"Smart Start",plan:"150Mbps",day:"90 Days",price:"2,100/-"},
   {title:"Plan F",subTitle:"Long Plans",plan:"200Mbps",day:"90 Days",price:"2,600/-"},
   {title:"Plan G",subTitle:"Huge Start",plan:"300Mbps",day:"90 Days",price:"3,200/-"}
]

export const yearlyData=[
   {title:"Plan D",subTitle:"Good Start",plan:"100Mbps",day:"360 Days",price:"6,200/-"},
   {title:"Plan E",subTitle:"Smart Start",plan:"150Mbps",day:"360 Days",price:"7,500/-"},
   {title:"Plan F",subTitle:"Long Plans",plan:"200Mbps",day:"360 Days",price:"9,000/-"},
   {title:"Plan G",subTitle:"Huge Start",plan:"300Mbps",day:"360 Days",price:"11,000/-"},
   {title:"Plan A",subTitle:"Starter Kit",plan:"15Mbps",day:"360 Days",price:"3,300/-"},
   {title:"Plan B",subTitle:"New Beginnings",plan:"25Mbps",day:"360 Days",price:"4,000/-"},
   {title:"Plan C",subTitle:"Love to Start",plan:"40Mbps",day:"360 Days",price:"4,500/-"},
   {title:"Plan C",subTitle:"Serious Start",plan:"70Mbps",day:"360 Days",price:"5,500/-"},

]
export const yearlyAndOTTData=[
   {title:"Plan D",subTitle:"OTT+ Good Start",plan:"100Mbps",day:"360 Days",price:"7,800/-",ott:true},
   {title:"Plan E",subTitle:"OTT+ Smart Start",plan:"150Mbps",day:"360 Days",price:"9,200/-",ott:true},
   {title:"Plan F",subTitle:"OTT+ Long Plans",plan:"200Mbps",day:"360 Days",price:"10,600/-",ott:true},
   {title:"Plan G",subTitle:"OTT + Huge Start",plan:"300Mbps",day:"360 Days",price:"12,500/-",ott:true},


]

export const sixMonthData=[
   {title:"Plan D",subTitle:"Good Start",plan:"100Mbps",day:"180 Days",price:"3,250/-"},
   {title:"Plan E",subTitle:"Smart Start",plan:"150Mbps",day:"180 Days",price:"4,000/-"},
   {title:"Plan F",subTitle:"Long Plans",plan:"200Mbps",day:"180 Days",price:"4,850/-"},
   {title:"Plan G",subTitle:"Huge Start",plan:"300Mbps",day:"180 Days",price:"6,000/-"},
   {title:"Plan A",subTitle:"Starter Kit",plan:"15Mbps",day:"180 Days",price:"1,700/-"},
   {title:"Plan B",subTitle:"New Beginnings",plan:"25Mbps",day:"180 Days",price:"2,100/-"},
   {title:"Plan C",subTitle:"Love to Start",plan:"40Mbps",day:"180 Days",price:"2,300/-"},
   {title:"Plan C",subTitle:"Serious Start",plan:"70Mbps",day:"180 Days",price:"2,850/-"},
  
]

export const ottData=[
   {title:"Plan D",subTitle:"OTT+ Good Start",plan:"100Mbps",day:"360 Days",price:"7,800/-",ott:true},
   {title:"Plan E",subTitle:"OTT+ Smart Start",plan:"150Mbps",day:"360 Days",price:"9,200/-",ott:true},
   {title:"Plan F",subTitle:"OTT+ Long Plans",plan:"200Mbps",day:"360 Days",price:"10,600/-",ott:true},
   {title:"Plan G",subTitle:"OTT + Huge Start",plan:"300Mbps",day:"360 Days",price:"12,500/-",ott:true}
  
]