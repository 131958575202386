import React from 'react'
import Amazon from "../assets/Amazon.png"
import epicon from "../assets/epicon.png"
import hotstar from "../assets/hotstar.png"
import playbox from "../assets/playbox.png"
import sun from "../assets/sun.png"
import Shemaroo from "../assets/Shemaroo.png"
import sony from "../assets/sony.png"
import Zee5 from "../assets/ZEE5.png"
import Voot from "../assets/Voot.png"
import { ottData } from '../constants/data'

import Navbar from "../components/Navbar"
import Card from '../components/Card'

const OTT = () => {
  const icon=[Amazon, epicon]
  return (
    <div>
    <Navbar />
        <h1 className='text-center mt-12 text-3xl font-bold text-teal-600'>Yearly OTT Subscription</h1>
        <div className='flex justify-center items-start flex-wrap'>
        {ottData?.map((plan,key)=>(
            <Card plan={plan}  />
        ))}
            </div>
    </div>
  )
}

export default OTT