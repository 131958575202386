import React from 'react'
import { yearlyAndOTTData } from '../../constants/data'
import Card from '../Card'

const OneYearOTT = () => {
  return (
    <div className='flex justify-center items-center flex-wrap'>
         {yearlyAndOTTData.map((plan,key)=>(
      <Card key={key}  plan={plan}/>
    ))}
    </div>
  )
}

export default OneYearOTT