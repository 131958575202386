import React,{useState} from 'react'
import { fiftyMbpsPlan, hunderedMbpsPlan, oneFiftyMbpsPlan, tenMbpsPlan, threeHundredMbpsPlan, twentyFiveMbpsPlan, twentyMbpsPlan, twoHundredMbpsPlan } from '../../constants/data'
import { BadgeCheckIcon, ClockIcon,ArrowCircleRightIcon } from "@heroicons/react/outline";
import {Link} from 'react-router-dom';
const PlanMonth = (props) => {
  
  return (
    <>
      <h1 className='block text-center mt-10 text-2xl font-bold text-orange-600'>{props.plans} Plans</h1>
    
    <div className='mt-3 flex items-center justify-center md:justify-around gap-10 p-12 flex-wrap'>
     
     {props.plans==="15Mbps" && tenMbpsPlan.map((plan,key)=>(
        // eslint-disable-next-line react/jsx-key
        <div key={key} className='bg-gradient-to-r  from-emerald-600 to-teal-600 rounded-xl shadow-lg'>
        <div className='flex items-center justify-center mt-3'>
        <BadgeCheckIcon className='w-12 h-12 text-white' />
        </div>
        <div className=' flex items-center justify-center' >
            <p className='text-white text-xl p-5 font-bold'>{plan.days}</p>
            <p className='text-white text-xl p-5 font-bold'>Rs. {plan.price}</p>
      </div>
      </div>
     )) }
     {props.plans==="25Mbps" && twentyMbpsPlan.map((plan,key)=>(
        // eslint-disable-next-line react/jsx-key
       <div key={key} className='bg-gradient-to-r  from-emerald-600 to-teal-600 rounded-xl shadow-lg'>
        <div className='flex items-center justify-center mt-3'>
        <BadgeCheckIcon className='w-12 h-12 text-white' />
        </div>
        <div className=' flex items-center justify-center' >
            <p className='text-white text-xl p-5 font-bold'>{plan.days}</p>
            <p className='text-white text-xl p-5 font-bold'>Rs. {plan.price}</p>
      </div>
      </div>
     )) }
     {props.plans==="40Mbps" && twentyFiveMbpsPlan.map((plan,key)=>(
        // eslint-disable-next-line react/jsx-key
       <div key={key} className='bg-gradient-to-r  from-emerald-600 to-teal-600 rounded-xl shadow-lg'>
        <div className='flex items-center justify-center mt-3'>
        <BadgeCheckIcon className='w-12 h-12 text-white' />
        </div>
        <div className=' flex items-center justify-center' >
            <p className='text-white text-xl p-5 font-bold'>{plan.days}</p>
            <p className='text-white text-xl p-5 font-bold'>Rs. {plan.price}</p>
      </div>
      </div>
     )) }
     {props.plans==="70Mbps" && fiftyMbpsPlan.map((plan,key)=>(
        // eslint-disable-next-line react/jsx-key
        <div key={key} className='bg-gradient-to-r  from-emerald-600 to-teal-600 rounded-xl shadow-lg'>
        <div className='flex items-center justify-center mt-3'>
        <BadgeCheckIcon className='w-12 h-12 text-white' />
        </div>
        <div className=' flex items-center justify-center' >
            <p className='text-white text-xl p-5 font-bold'>{plan.days}</p>
            <p className='text-white text-xl p-5 font-bold'>Rs. {plan.price}</p>
      </div>
      </div>
     )) }
     {props.plans==="100Mbps" && hunderedMbpsPlan.map((plan,key)=>(
        // eslint-disable-next-line react/jsx-key
       <div key={key} className='bg-gradient-to-r  from-emerald-600 to-teal-600 rounded-xl shadow-lg'>
        <div className='flex items-center justify-center mt-3'>
        <BadgeCheckIcon className='w-12 h-12 text-white' />
        </div>
        <div className=' flex items-center justify-center' >
            <p className='text-white text-xl p-5 font-bold'>{plan.days}</p>
            <p className='text-white text-xl p-5 font-bold'>Rs. {plan.price}</p>
      </div>
      </div>
     )) }
      {props.plans==="150Mbps" && oneFiftyMbpsPlan.map((plan,key)=>(
        // eslint-disable-next-line react/jsx-key
        <div key={key} className='bg-gradient-to-r  from-emerald-600 to-teal-600 rounded-xl shadow-lg'>
        <div className='flex items-center justify-center mt-3'>
        <BadgeCheckIcon className='w-12 h-12 text-white' />
        </div>
        <div className=' flex items-center justify-center' >
            <p className='text-white text-xl p-5 font-bold'>{plan.days}</p>
            <p className='text-white text-xl p-5 font-bold'>Rs. {plan.price}</p>
      </div>
      </div>
     )) }
     {props.plans==="200Mbps" && twoHundredMbpsPlan.map((plan,key)=>(
        // eslint-disable-next-line react/jsx-key
       <div key={key} className='bg-gradient-to-r  from-emerald-600 to-teal-600 rounded-xl shadow-lg'>
        <div className='flex items-center justify-center mt-3'>
        <BadgeCheckIcon className='w-12 h-12 text-white' />
        </div>
        <div className=' flex items-center justify-center' >
            <p className='text-white text-xl p-5 font-bold'>{plan.days}</p>
            <p className='text-white text-xl p-5 font-bold'>Rs. {plan.price}</p>
      </div>
      </div>
     )) }
      {props.plans==="300Mbps" && threeHundredMbpsPlan.map((plan,key)=>(
        // eslint-disable-next-line react/jsx-key
       <div key={key} className='bg-gradient-to-r  from-emerald-600 to-teal-600 rounded-xl shadow-lg'>
        <div className='flex items-center justify-center mt-3'>
        <BadgeCheckIcon className='w-12 h-12 text-white' />
        </div>
        <div className=' flex items-center justify-center' >
            <p className='text-white text-xl p-5 font-bold'>{plan.days}</p>
            <p className='text-white text-xl p-5 font-bold'>Rs. {plan.price}</p>
      </div>
      </div>
     )) }
    </div>
    <div className='flex items-center justify-center'>
    <Link  to="/contact"  className=" mt-5 lg:mt-5   whitespace-nowrap inline-flex items-center justify-center px-6 py-3 lg:px-8 lg:py-4 border border-transparent rounded-md shadow-sm text-base  lg:text-2xl text-white bg-emerald-600 hover:bg-emerald-700">
               Enquire<ArrowCircleRightIcon className=' h-8 w-8 ml-4 text-white text-lg'/>
            </Link>
    </div>
    </>
  )
}

export default PlanMonth